import React from "react"

import Layout from "../layouts/default"
import Seo from "../components/seo"
import PageTitle from "../components/page-title"
import Paragraph from "../components/paragraph"
import Stack from "../components/stack"
import QuotePrimary from "../components/quote-primary"
import Constrain from "../components/constrain"

const NotFoundPage = () => (
  <Layout backdrop="404">
    <Seo title="Seite nicht gefunden" />
    <Stack>
      <PageTitle>Seite nicht gefunden</PageTitle>
      <Paragraph>
        Die gewünschte Seite konnte leider nicht gefunden werden.
      </Paragraph>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Einst hab ich die Muse gefragt...">
          <span>Einst hab ich die Muse gefragt, und sie</span>
          <span>Antwortete mir</span>
          <span>Am Ende wirst du es finden.</span>
        </QuotePrimary>
      </Constrain>
    </Stack>
  </Layout>
)

export default NotFoundPage
